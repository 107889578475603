export class NavigationExtensions {

    RemoveQueryParameter(paramName: string) {
        var url = new URL(window.location.href);

        url.searchParams
            .delete(paramName);

        window.history
            .replaceState({}, document.title, url.toString());
    };
}