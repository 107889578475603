export class StaticFunctions {
    static DebounceEvent(fn, delay) {
        var timer = null;
        return function () {
            var context = this, args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        };
    }

    static AddDebounceEventListener(eventName: string,
        referenceMethodName: string,
        debounceDurationInMs: number, objectReference) {
        if (objectReference != null) {
            window.addEventListener(eventName, StaticFunctions.DebounceEvent(
                function () {
                    objectReference.invokeMethodAsync(referenceMethodName).then(data => data);
                }, debounceDurationInMs));
        }
    }

    static UpdateQueryStringParameter(url: string, key: string, value: string): string {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = url.indexOf('?') !== -1 ? "&" : "?";
        if (url.match(re)) {
            return url.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            return url + separator + key + "=" + value;
        }
    }
}
