export class ResizeObserverExtensions {
    private observers: Map<string, ResizeObserver> = new Map();

    public Subscribe(elementId: string, blazorObject: any, blazorMethod: string): void {
        const element = document.querySelector(elementId);
        if (!element) {
            throw new Error(`Element with ID "${elementId}" not found.`);
        }

        const observer = new ResizeObserver(entries => {
            for (const entry of entries) {
                const { width, height } = entry.contentRect;
                blazorObject.invokeMethodAsync(blazorMethod, width, height);
            }
        });

        observer.observe(element);
        this.observers.set(elementId, observer);
    }

    public Unsubscribe(elementId: string): void {
        const observer = this.observers.get(elementId);
        if (observer) {
            observer.unobserve(document.querySelector(elementId)!);
            this.observers.delete(elementId);
        }

    }
}