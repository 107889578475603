export class JavascriptHandler {
    private ReferenceAttrName: string = "injector-references";

    private loadScript(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = "text/javascript";
            script.async = false;
            script.src = url;
            script.setAttribute(this.ReferenceAttrName, "1");

            script.addEventListener('load', () => {
                resolve();
            });

            script.addEventListener('error', (event) => {
                reject(new Error(`Failed to load script: ${url}`));
            });

            document.head.appendChild(script);
        });
    }

    async LoadScripts(fileNames: string[], callbackFunctionName: string, objectReference: any, uniqueId: string) {
        const loadedScripts = Array.from(document.querySelectorAll('script'));

        for (const filename of fileNames) {
            const existingScripts = loadedScripts.filter(elm => elm.src.endsWith(filename)
                && elm.hasAttribute(this.ReferenceAttrName)
            );

            if (existingScripts.length > 0) {
                const ref = Number(existingScripts[0].getAttribute(this.ReferenceAttrName)) + 1;
                existingScripts[0].setAttribute(this.ReferenceAttrName, ref.toString());

                await objectReference.invokeMethodAsync(callbackFunctionName, filename, uniqueId);
            }
            else {
                console.info(`JavascriptHandler Load: ${filename} (${uniqueId})`);

                try {
                    await this.loadScript(filename);
                    await objectReference.invokeMethodAsync(callbackFunctionName, filename, uniqueId);
                }
                catch (error) {
                    console.error(error);
                }
            }
        }
    }

    UnloadScripts(fileNames) {
        var loadedScripts = Array.from(document.querySelectorAll('script'));
        fileNames = typeof fileNames === 'string' ? [fileNames] : fileNames;

        loadedScripts.filter(s => fileNames.some(f => s.src.endsWith(f) && s.hasAttribute(this.ReferenceAttrName)))
            .forEach(script => {
                var ref = Number(script.getAttribute(this.ReferenceAttrName));

                if (ref <= 1) {
                    console.info("JavascriptHandler Unload: " + script.src);
                    script.parentNode.removeChild(script);
                }
                else {
                    ref = ref - 1;
                    script.setAttribute(this.ReferenceAttrName, ref.toString());
                }
            });
    }
}