export class CssLinkHandler {
    private ReferenceAttrName: string = "injector-references";

    private LoadCssFile(url: string): Promise<void> {
        return new Promise((resolve, reject) => {
            var link = (document.createElement('link') as HTMLLinkElement);

            link.href = url;
            link.rel = "stylesheet";
            link.setAttribute(this.ReferenceAttrName, "1");

            link.addEventListener('load', function () {
                resolve();
            });

            link.addEventListener('error', (event) => {
                reject(new Error(`Failed to load link: ${url}`));
            });

            document.head.appendChild(link);
        });
    }

    async LoadCss(fileNames: string[], callbackFunctionName: string, objectReference: any, uniqueId: string) {
        const loadedLinks = Array.from(document.querySelectorAll('link'));

        for (const filename of fileNames) {
            const existingFiles = loadedLinks.filter(elm => elm.href.endsWith(filename)
                && elm.hasAttribute(this.ReferenceAttrName)
                && elm.rel == "stylesheet");

            if (existingFiles.length > 0) {
                const ref = Number(existingFiles[0].getAttribute(this.ReferenceAttrName)) + 1;
                existingFiles[0].setAttribute(this.ReferenceAttrName, ref.toString());

                await objectReference.invokeMethodAsync(callbackFunctionName, filename, uniqueId);
            }
            else {
                console.info(`CssLinkHandler Load: ${filename} (${uniqueId})`);

                try {
                    await this.LoadCssFile(filename);
                    await objectReference.invokeMethodAsync(callbackFunctionName, filename, uniqueId);
                }
                catch (error) {
                    console.error(error);
                }
            }
        }
    }

    UnloadCss(fileNames) {
        var loadedStyles = Array.from(document.querySelectorAll('link'));
        fileNames = typeof fileNames === 'string' ? [fileNames] : fileNames;

        loadedStyles.filter(s => fileNames.some(f => s.href.endsWith(f) && s.hasAttribute(this.ReferenceAttrName) && s.rel == "stylesheet"))
            .forEach(link => {
                var ref = Number(link.getAttribute(this.ReferenceAttrName));

                if (ref <= 1) {
                    console.info("CssLinkHandler Unload: " + link.href);
                    link.parentNode.removeChild(link);
                }
                else {
                    ref = ref - 1;
                    link.setAttribute(this.ReferenceAttrName, ref.toString());
                }
            });
    }
}