interface EventListener {
    elements: NodeListOf<Element>;
    listener: (event: KeyboardEvent) => void;
}

const eventListeners: { [id: string]: EventListener } = {};

export class EventListenerExtensions {
    private callbacks = new Map();



    public SubscribeById(eventName: string, objectRef: any, methodName: string, elementId: string) {
        var element = document.querySelector(elementId);
        if (element != undefined)
            this.Subscribe(eventName, objectRef, methodName, element);
        else
            console.error('Unable to subscribe to event ${eventName} for element id ${elementId}');
    }

    public Subscribe(eventName: string, objectRef: any, methodName: string, element: any = window) {
        const callback = () => {
            console.warn(methodName);
            objectRef.invokeMethodAsync(methodName);
        };

        this.callbacks.set(callback, {
            element,
            eventName,
            callback,
            objectRef,
            methodName
        });

        element.addEventListener(eventName, callback);
    }

    public UnsubscribeById(elementId: string, eventName: string, objectRef: any, methodName: string) {
        var element = document.querySelector(elementId);
        if (element != undefined)
            this.Unsubscribe(eventName, objectRef, methodName, element);
    }

    public Unsubscribe(eventName: string, objectRef: any, methodName: string, element: any = window) {
        const entry = [...this.callbacks.values()]
            .find((entry) => entry.element === element &&
                entry.eventName === eventName &&
                entry.objectRef === objectRef &&
                entry.methodName === methodName
            );

        if (entry) {
            const { callback } = entry;
            this.callbacks.delete(callback);
            element.removeEventListener(eventName, callback);
        }
    }

    public SubscribeToPreventDefaultOnEnterKey(id: string): void {
        const listener = (event: KeyboardEvent) => {
            const target = event.target as Node;
            if ((event.key === 'Enter' || event.key === 'Return') &&
                (target.nodeName !== 'TEXTAREA' && target.nodeName !== 'BUTTON')) {
                event.preventDefault();
            }
        };

        const elements = document.querySelectorAll(`#${id}, #${id} *`);
        elements.forEach(element => {
            element.addEventListener('keydown', listener);
        });

        eventListeners[id] = { elements, listener };
    }

    public UnsubscribeFromPreventDefaultOnEnterKey(id: string): void {
        const { elements, listener } = eventListeners[id];
        elements.forEach(element => {
            element.removeEventListener('keydown', listener);
        });

        delete eventListeners[id];
    }
}