export class CssClassInjector {
    InjectClass(elementId: string, className: string) {
        const element = document.getElementById(elementId);

        if (element && !element.classList.contains(className)) {
            element.classList.add(className);
        }
    }

    RemoveClass(elementId: string, className: string) {
        const element = document.getElementById(elementId);

        if (element && element.classList.contains(className)) {
            element.classList.remove(className);
        }
    }

    InjectClassWithDelay(elementId: string, className: string, delay: number) {
        const element = document.getElementById(elementId);

        if (element && !element.classList.contains(className)) {
            element.classList.add(className);

            setTimeout(() => {
                this.RemoveClass(elementId, className);
            }, delay);
        }
    }
}