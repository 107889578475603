// Adapted from https://developer.chrome.com/docs/workbox/handling-service-worker-updates/
import { Workbox } from 'workbox-window/Workbox.mjs';
import { StaticFunctions } from './StaticFunctions';

export class ServiceWorkerUpdate {

    SkipWaiting(serviceWorkerPath: string) {

        if ('serviceWorker' in navigator) {
            const wb = new Workbox(serviceWorkerPath);

            const skipWaitingOrReload = async (event) => {
                // that will reload the page as soon as the previously waiting
                // service worker has taken control.
                wb.addEventListener('controlling', () => {
                    // At this point, reloading will ensure that the current
                    // tab is loaded under the control of the new service worker.
                    // Depending on your web app, you may want to auto-save or
                    // persist transient state before triggering the reload.
                    window.location.reload();
                });

                console.info('Service Worker Workbook : Skip Waiting');
                wb.messageSkipWaiting();
            };

            // Add an event listener to detect when the registered
            // service worker has installed but is waiting to activate.
            wb.addEventListener('waiting', (event) => {
                skipWaitingOrReload(event);
            });

            console.info('Service Worker : Register Workbook');
            wb.register();
        }
    }

    async UpdateVersion(newVersion: string, force: boolean, notForcedmessage: string) {
        var key = 'simpleter-app-version';

        var oldVersion = localStorage.getItem(key);

        console.info('Old Version: ' + oldVersion + ' - New Version: ' + newVersion + ' - Force: ' + force);

        var continueUpdate = false;

        if (oldVersion == null || newVersion != oldVersion || force == true) {
            continueUpdate = true;
        }

        if (continueUpdate == false && force == false) {
            if (confirm(notForcedmessage)) {
                continueUpdate = true;
            }
        }

        if (continueUpdate) {

            // Blazor and offline cache deletion
            console.log("Blazor offline ressources and cache deletion...");

            let deletionPromises = [];

            try {
                await caches.keys().then(async (keys) => {
                    await keys.map(async (key) => {
                        if (key.startsWith("offline-cache-") || key.startsWith("blazor-resources-")) {
                            deletionPromises.push(
                                caches.delete(key).then(function (e) {
                                    console.log("[" + key + "] cache deleted");
                                })
                            );

                        }
                    });
                });
            }
            catch (e) {
                console.error('Blazor offline cache deletion failed: ', e);
            }

            await Promise.all(deletionPromises).then(async () => {
                // Unregister service worker
                console.info('Service Worker unregister')
                if ('serviceWorker' in navigator) {
                    await navigator.serviceWorker.getRegistrations().then(async (registrations) => {
                        console.log('Nombre de serice workers' + registrations.length);
                        for (let registration of registrations) {
                            console.log(' => unregister: ' + registration.scope);
                            await registration.unregister();
                        }
                    }).catch(function (err) {
                        console.error('Service Worker unregister failed: ', err);
                    });
                }

                // Set version
                console.info('Version cache updated to new version: ' + newVersion);
                localStorage.setItem(key, newVersion);

                // Reload
                // Since window.location.reload(true) has been deprecated.You can use:
                console.info('Update : Reload application');

                var newLocation = StaticFunctions.UpdateQueryStringParameter(window.location.href, "update", "true");
                window.location.replace(newLocation);
            });
        }
    }
}