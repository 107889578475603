export class MovableModalExtensions {
    private elementHeader: HTMLElement;
    private elementToMove: HTMLElement;
    private inDrag: boolean;
    private startX: number;
    private startY: number;
    private dragMouseDownListener: any;
    private closeDragElementListener: any;
    private elementDragListener: any;
    private resizeListener: any;

    constructor() {
        this.inDrag = false;
        this.resizeListener = this.handleWindowResize.bind(this);
    }

    RegisterModalHandler(elementToMoveId: string, elementHeaderId?: string) {
        this.elementToMove = document.getElementById(elementToMoveId);

        if (elementHeaderId != null) {
            this.elementHeader = document.getElementById(elementHeaderId);
        } else {
            this.elementHeader = this.elementToMove;
        }

        if (this.elementHeader != undefined && this.elementToMove != undefined) {
            if (this.dragMouseDownListener != undefined) {
                this.elementHeader.removeEventListener("mousedown", this.dragMouseDownListener);
            }

            this.dragMouseDownListener = this.DragMouseDown.bind(this);
            this.closeDragElementListener = this.CloseDragElement.bind(this);
            this.elementDragListener = this.ElementDrag.bind(this);

            this.elementHeader.style.cursor = "pointer";
            this.elementHeader.addEventListener("mousedown", this.dragMouseDownListener);
            window.addEventListener("resize", this.resizeListener);
        }
    }

    DragMouseDown(e: MouseEvent) {
        document.addEventListener("mouseup", this.closeDragElementListener);
        document.addEventListener("mousemove", this.elementDragListener);

        this.inDrag = true;
        this.startX = e.clientX;
        this.startY = e.clientY;

    }

    ElementDrag(e: MouseEvent) {
        if (this.inDrag) {
            e.preventDefault();

            var deltaX = e.clientX - this.startX;
            var deltaY = e.clientY - this.startY;
            var modalWindow = this.elementToMove;

            var computedStyles = getComputedStyle(modalWindow);
            var leftValue = parseInt(computedStyles.getPropertyValue('left'), 10) || 0;
            var topValue = parseInt(computedStyles.getPropertyValue('top'), 10) || 0;

            var newLeft = leftValue + deltaX;
            var newTop = topValue + deltaY;

            var viewportWidth = window.innerWidth;
            var viewportHeight = window.innerHeight;

            var modalWindowWidth = modalWindow.offsetWidth / 2;
            var modalWindowHeight = modalWindow.offsetHeight / 2;

            newLeft = Math.max(0, Math.min(newLeft, viewportWidth - modalWindowWidth));
            newTop = Math.max(0, Math.min(newTop, viewportHeight - modalWindowHeight));

            if (newTop < 80)
                newTop = 80;

            modalWindow.style.left = newLeft + 'px';
            modalWindow.style.top = newTop + 'px';

            this.startX = e.clientX;
            this.startY = e.clientY;
        }
    }

    CloseDragElement() {
        this.inDrag = false;
        document.removeEventListener("mouseup", this.closeDragElementListener);
        document.removeEventListener("mousemove", this.elementDragListener);
    }

    handleWindowResize() {
        this.elementToMove.style.left = null;
        this.elementToMove.style.top = null;

    }
}
