
export class ConnectionExtensions {

    ConnectionHandler: EventListener;

    Initialize(interop) {

        this.ConnectionHandler = function () {
            interop.invokeMethodAsync("Connection.StatusChanged", navigator.onLine);
        }

        window.addEventListener("online", this.ConnectionHandler);
        window.addEventListener("offline", this.ConnectionHandler);

        this.ConnectionHandler(new Event("init"));
    }

    Dispose() {

        if (this.ConnectionHandler != null) {

            window.removeEventListener("online", this.ConnectionHandler);
            window.removeEventListener("offline", this.ConnectionHandler);
        }
    }
}