// Import External Javascript Modules
import * as bootstrap from "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min";

export class PopoverExtensions {
    private observers: { [key: string]: IntersectionObserver } = {};

    Init(querySelector: string) {
        var selector = document.querySelector(querySelector);
        if (selector != undefined) {
            var popover = new bootstrap.Popover(selector, {
                boundary: document.body
            });
            return popover;
        } else {
            return null;
        }
    }

    InitWithCallback(querySelector: string, blazorObject: any, blazorMethod: string) {
        var selector = document.querySelector(querySelector);
        if (selector != undefined) {
            var popover = new bootstrap.Popover(selector, {
                boundary: document.body
            });

            var test = blazorObject.invokeMethodAsync(blazorMethod)
            this.ObserveVisibility(
                selector, querySelector, blazorObject, blazorMethod
            );
            return popover;
        } else {
            return null;
        }
    }

    ObserveVisibility(element: Element, querySelector: string, blazorObject: any, blazorMethod: string) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    let popover = bootstrap.Popover.getInstance(element);
                    if (popover) {
                        popover.hide();
                        blazorObject.invokeMethodAsync(blazorMethod);
                    }
                }
            });
        });

        this.observers[querySelector] = observer;
        observer.observe(element);
    }

    DisconnectObserver(querySelector: string) {
        const observer = this.observers[querySelector];
        if (observer) {
            observer.disconnect(); delete this.observers[querySelector];
        }
    }
}
export class DropdownExtensions {
    Init(componentId: string, showEventName: string, hideEventName: string, objectReference) {
        var dropdownElement = document.querySelector(componentId);
        if (!dropdownElement)
            return null;

        var dropdown = new bootstrap.Dropdown(dropdownElement);

        if (showEventName != null) {
            window.addEventListener("show.bs.dropdown", function () {
                objectReference.invokeMethodAsync(showEventName).then(data => data);
            });
        }

        if (hideEventName != null) {
            window.addEventListener("hide.bs.dropdown", function () {
                objectReference.invokeMethodAsync(hideEventName).then(data => data);
            });
        }

        return dropdown;
    }

    Toggle(id: string) {
        var dropdownElement = document.querySelector(id);
        var dropdown = bootstrap.Dropdown.getInstance(dropdownElement);
        if (dropdown != undefined)
            dropdown.toggle();
    }

    Show(id: string) {
        var dropdownElement = document.querySelector(id);
        var dropdown = bootstrap.Dropdown.getInstance(dropdownElement);
        if (dropdown != undefined)
            dropdown.show();
    }

    Hide(id: string) {
        var dropdownElement = document.querySelector(id);
        var dropdown = bootstrap.Dropdown.getInstance(dropdownElement);
        if (dropdown != undefined)
            dropdown.hide();
    }

}